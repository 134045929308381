import { useEffect, useRef } from 'react';


/**
 * Use component did mount
 *
 * @param {React.EffectCallback} effect Imperative function that can return a cleanup function
 * @param {React.DependencyList} deps If present, effect will only activate if the values in the list change.
 */
function useComponentDidMount (effect, deps) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      effect();
    } else {
      ref.current = true;
    }
  }, deps);
}

export default useComponentDidMount;
