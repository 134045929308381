import { observable, runInAction } from 'mobx';

import project from '~/assets/json/project.json';


class ProjectStore {
  @observable
  title = '';

  @observable
  favicon = '';

  @observable
  banner = '';

  /**
   * Constructor
   *
   * @constructor
   * @param {'terminal21' | 'fashionisland'} id - project id
   */
  constructor (id) {
    const { title, favicon, banner } = project[id];

    runInAction(() => {
      this.title = title;
      this.favicon = favicon;
      this.banner = banner;
    });
  }
}

export default {};
export { ProjectStore };
