import { observable, action, runInAction } from 'mobx';


class ServiceStore {
  @observable
  loading = false;

  @observable
  error = {};

  @observable
  profile = {};

  @observable
  members = {};

  @observable
  questions = [];

  /**
   * Constructor
   *
   * @constructor
   * @param {any} memberService
   * @param {any} questionService
   */
  constructor (memberService, questionService) {
    this.memberService = memberService;
    this.questionService = questionService;
  }

  /**
   * Set line user profile
   *
   * @param {any} profile - line user profile
   * @return {void}
   */
  @action.bound
  setProfile (profile) {
    this.profile = profile;
  }

  /**
   * Set member
   *
   * @param {string} line - line user id (from line api)
   * @return {Promise<void>}
   */
  @action.bound
  async setMember (line) {
    this.loading = true;

    try {
      const { data } = await this.memberService.find({ line, $cin: 1 }); // $cin (create if not exist)

      runInAction(() => {
        this.member = data.shift();
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
        this.loading = false;
      });

      throw new Error(error);
    }
  }

  /**
   * Upgrade class of member from non member to member
   *
   * @param {string | number} id
   * @param {any} data
   * @return {Promise<void>}
   */
  @action.bound
  async register (id, data) {
    this.loading = true;

    try {
      const response = await this.memberService.put(id, data);

      runInAction(() => {
        this.member = response.data;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
        this.loading = false;
      });

      throw new Error(error);
    }
  }

  /**
   * Set question
   *
   * @return {Promise<void>}
   */
  @action.bound
  async setQuestions () {
    this.loading = true;

    try {
      const { data } = await this.questionService.find({ $populate: [ 'choices' ] });

      runInAction(() => {
        this.questions = data;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
        this.loading = false;
      });

      throw new Error(error);
    }
  }
}

export default {};
export { ServiceStore };
