import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Card, Avatar, Skeleton } from 'antd';


/**
 * Profile component
 *
 * @param {Object} props
 * @param {string} [props.src='']
 * @param {string} [props.name='']
 * @param {string} [props.status='']
 * @param {boolean} [props.loading=false]
 * @param {boolean} [props.fullwidth=false]
 * @return {JSX.Element}
 */
function Profile ({ src, name, status, loading, fullwidth }) {
  return (
    <div className={cx('profile', { 'is-fullwidth': fullwidth })}>
      <Card>
        <Skeleton loading={loading} avatar active>
          <div className="profile-card">
            <div className="profile-avatar">
              <Avatar size="large" src={src} />
            </div>

            <div className="profile-detail">
              <h3 className="profile-detail-name">{name}</h3>
              <p className="profile-detail-status">{status}</p>
            </div>
          </div>
        </Skeleton>
      </Card>
    </div>
  );
}

Profile.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool,
  fullwidth: PropTypes.bool,
};

Profile.defaultProps = {
  src: '',
  name: '',
  status: '',
  loading: false,
  fullwidth: false,
};

export default Profile;
