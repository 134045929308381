import { useEffect } from 'react';


/**
 * Use click outside hooks
 * @see {@link https://usehooks.com/useOnClickOutside/}
 *
 * @param {React.RefObject} ref
 * @param {React.EffectCallback} effect
 */
function useClickOutside (ref, effect) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;
      effect(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ ref, effect ]);
}

export default useClickOutside;
