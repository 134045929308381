import { render, unmountComponentAtNode } from 'react-dom';
import { createElement } from 'react';

import Modal from './components/Modal';

function confirm () {
  const id = 'confirm-dialog';
  const rootEl = document.getElementById('root');
  const container = document.createElement('div');
  container.setAttribute('id', id);

  const onClose = () => {
    // enable scroll on body
    document.body.style.overflow = 'auto';

    setTimeout(() => unmountComponentAtNode(container), 400);
  };

  const onOpen = (params) => {
    // disable scroll on body
    document.body.style.overflow = 'hidden';

    const dialog = createElement(Modal, { ...params, onClose });
    render(dialog, rootEl.appendChild(container));
  };

  return {
    error (params) {
      onOpen({ ...params, type: 'error' });
    },
    info (params) {
      onOpen({ ...params, type: 'info' });
    },
    success (params) {
      onOpen({ ...params, type: 'success' });
    },
  };
}

export default confirm();
