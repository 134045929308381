import { Service } from './base/service';


class MemberService extends Service {
  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   * @param {stirng} [url='/members']
   */
  constructor (fetcher, url = '/members') {
    super(fetcher, url);
  }
}

export default {};
export { MemberService };
