import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { useObserver } from 'mobx-react-lite';
import { Switch } from 'react-router-dom';

import config from '~/lib/config';
import { useStore, useLIFF } from '~/hooks';
import { Confirm } from '~/components';


/**
 * Loading switch component
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @return {JSX.Element}
 */
function LoadingSwitch ({ children }) {
  const { project, service } = useStore();
  const liff = useLIFF(config.LIFF_ID);

  useEffect(() => {
    const init = async () => {
      try {
        await liff.init();

        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();

          await Promise.all([ service.setProfile(profile), service.setMember(profile.userId), service.setQuestions() ]);
        } else {
          liff.login();
        }
      } catch (error) {
        console.error(error);
        Confirm.error({
          title: 'Oops',
          description: 'Something went wrong.',
          buttons: [{ text: 'Dismiss', onClick: () => liff.closeWindow() }],
        });
      }
    };

    init();
  }, []);

  return useObserver(() => (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={project.favicon} type="image/x-icon" />
        <link rel="icon" href={project.favicon} type="image/x-icon" />
        <title>{project.title}</title>
      </Helmet>

      <Switch>
        {children}
      </Switch>
    </Fragment>
  ));
}

LoadingSwitch.defaultProps = {};
LoadingSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoadingSwitch;
