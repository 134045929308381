import React, { Fragment } from 'react';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useObserver } from 'mobx-react-lite';

import Routes from '~/routes';

import 'antd/dist/antd.css';
import '~/scss/_main.scss';


/**
 * Application root
 *
 * @return {JSX.Element}
 */
function App () {
  const browserHistory = createBrowserHistory({
    basename: '', // The base URL of the app (see below)
    forceRefresh: false, // Set true to force full page refreshes
    keyLength: 6, // The length of location.key
    getUserConfirmation: (message, callback) => callback(window.confirm(message)), // A function to use to confirm navigation with the user (see below)
  });

  return useObserver(() => (
    <Fragment>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </Fragment>
  ));
}

export default App;
