import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { CheckCircleTwoTone, CloseCircleTwoTone, AlertTwoTone } from '@ant-design/icons';


/**
 * Modal component
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {any[]} props.buttons
 * @param {'success' | 'error' | 'info'} props.type
 * @param {Function} props.onClose
 * @return {JSX.Element}
 */
function Modal ({ title, description, buttons, type, onClose }) {
  const [ visible, setVisible ] = useState(true);

  const ref = useRef(null);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleTwoTone className="confirm-card-icon" twoToneColor="#52c41a" />;

      case 'error':
        return <CloseCircleTwoTone className="confirm-card-icon" twoToneColor="#eb2f96" />;

      default:
      case 'info':
        return <AlertTwoTone className="confirm-card-icon" />;
    }
  };

  return (
    <div
      className={cx(
        'confirm',
        { 'is-opened': visible },
        { 'is-closed': !visible },
        { [`is-${type}`]: type },
      )}
    >
      <div className="confirm-backdrop">
        <div className="confirm-backdrop-content">
          <div ref={ref} className="confirm-card">
            <div className="confirm-card-content">
              <div className="confirm-card-icon-container">
                {renderIcon()}
              </div>
              {title && <h3 className="confirm-card-title">{title}</h3>}
              {description && <p className="confirm-card-description">{description}</p>}
            </div>

            <div className="confirm-card-buttons">
              {buttons.map(({ text, onClick, style = 'default' }, index) => {
                const handleOnClick = () => {
                  if (onClick) onClick();
                  handleClose();
                };

                return (
                  <button
                    type="button"
                    key={index.toString()}
                    onClick={handleOnClick}
                    className={cx(
                      { [`is-${style}-style`]: style },
                    )}
                  >
                    {text}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
      style: PropTypes.oneOf([ 'default', 'cancel' ]),
    }),
  ),
  type: PropTypes.oneOf([ 'success', 'error', 'info' ]),
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  title: '',
  description: '',
  buttons: [
    { text: 'Cancel', onClick () {} },
  ],
  type: 'info',
  onClose () {},
};

export default Modal;
