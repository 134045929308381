import React from 'react';
import PropTypes from 'prop-types';


/**
 * Container component
 *
 * @param {Object} props
 * @return {JSX.Element}
 */
function Container ({ children }) {
  return (
    <div className="container">
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {};

export default Container;
