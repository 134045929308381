/**
 * Get env
 *
 * @param {string} property
 * @return {string}
 */
function get (property) {
  return process.env[`REACT_APP_${property}`];
}

export default {
  NODE_ENV: process.env.NODE_ENV,
  SERVICE: get('SERVICE'),
  SERVICE_VERSION: get('SERVICE_VERSION'),
  LIFF_ID: get('LIFF_ID'),
  PROJECT_ID: get('PROJECT_ID'),
  API_KEY: get('LAS_API_KEY'),
  TIER_ID: get('MEMBER_TIER_ID'),
};
