import React, { lazy, Suspense } from 'react';

import { Route } from 'react-router-dom';

import { Switch } from '~/explicit-component';


const allRoutes = [
  {
    path: '/',
    name: 'register',
    component: lazy(() => import(/* webpackChunkName: "register" */ '~/screens/Register')),
  },
  {
    name: 'not-found',
    component: lazy(() => import(/* webpackChunkName: "not-found" */ '~/screens/NotFound')),
  },
];

const routeForRender = [];
const collectRoutes = (routes) => {
  routes.forEach((route) => {
    routeForRender.push(route);
    if (route.routes) collectRoutes(route.routes);
  });
};

collectRoutes(allRoutes);

/**
 * Routes
 *
 * @return {JSX.Element}
 */
function Routes () {
  const renderRoutes = (routes) => (
    routes.map(
      ({ path, exact = true, component }, i) => (
        <Route
          key={i.toString()}
          path={path}
          exact={exact}
          component={component}
        />
      ),
    )
  );

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {renderRoutes(routeForRender)}
      </Switch>
    </Suspense>
  );
}

export default Routes;
