class Service {
  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   * @param {string} url
   */
  constructor (fetcher, url) {
    this.fetcher = fetcher;
    this.url = url;
  }

  find (params, headers) {
    const { url, fetcher } = this;

    return fetcher.get(url, { params, headers });
  }

  get (id, params, headers) {
    const { url, fetcher } = this;

    return fetcher.get(`${url}/${id}`, { params, headers });
  }

  post (data, params, headers) {
    const { url, fetcher } = this;

    return fetcher.post(url, data, { params, headers });
  }

  put (id, data, params, headers) {
    const { url, fetcher } = this;

    return fetcher.put(`${url}/${id}`, data, { params, headers });
  }
}

export default {};
export { Service };
