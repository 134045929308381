import axios from 'axios';

import config from './config';


/**
 * Axios intance
 *
 * learn more about creating an axios instance
 * @see {@link https://github.com/axios/axios#creating-an-instance | Axios}
 */
export default axios.create({
  baseURL: config.SERVICE + config.SERVICE_VERSION,
  headers: { key: config.API_KEY },
});
