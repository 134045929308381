import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


/**
 * List component
 *
 * @param {Object} props
 * @param {any[]} props.items
 * @param {Function} props.keyExtractor
 * @param {Function} props.render
 * @return {JSX.Element}
 */
function List ({ items, keyExtractor, render }) {
  return (
    <Fragment>
      {items.map((item, index) => (
        <Fragment key={keyExtractor(item, index)}>
          {render(item, index)}
        </Fragment>
      ))}
    </Fragment>
  );
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  keyExtractor: PropTypes.func,
  render: PropTypes.func.isRequired,
};

List.defaultProps = {
  keyExtractor () {},
};

export default List;
