import React from 'react';
import PropTypes from 'prop-types';


/**
 * Hero banner component
 *
 * @param {Object} props
 * @param {string} [props.src='']
 * @param {string} [props.alt='hero-banner']
 * @return {JSX.Element}
 */
function HeroBanner ({ src, alt }) {
  return (
    <div className="hero-banner">
      <img src={src} alt={alt} />
    </div>
  );
}

HeroBanner.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

HeroBanner.defaultProps = {
  src: '',
  alt: 'hero-banner',
};

export default HeroBanner;
