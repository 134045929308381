import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';


/**
 * Scroll to top
 *
 * @param {Object} props
 */
const ScrollToTop = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ props.location.pathname ]);
  return props.children;
};

export default withRouter(ScrollToTop);

ScrollToTop.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};
