import { createContext } from 'react';

import { configure } from 'mobx';
import { enableLogging } from 'mobx-logger';

import config from '~/lib/config';
import fetcher from '~/lib/fetcher';

import { ProjectStore } from '~/stores/project-store';
import { ServiceStore } from '~/stores/service-store';

import { MemberService, QuestionService } from '~/services';


// don't allow state modifications outside actions
// see more https://mobx.js.org/refguide/api.html#enforceactions
configure({ enforceActions: 'observed' });

// mobx logger
// see more https://github.com/winterbe/mobx-logger
if (config.NODE_ENV === 'development') enableLogging();

export default {};
export const StoreContext = createContext({
  project: new ProjectStore(config.PROJECT_ID),
  service: new ServiceStore(
    new MemberService(fetcher),
    new QuestionService(fetcher),
  ),
});
