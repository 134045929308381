import React, { useMemo } from 'react';

import _ from 'lodash';
import { Form, Select } from 'antd';

import countries from '~/assets/json/country.json';


/**
 * Country code selector
 *
 * @return {JSX.Element}
 */
function CountryCodeSelector () {
  // sorted countries
  const sorted = useMemo(() => (
    _.chain(countries).filter((country) => country.Dial).sortBy((country) => country.Dial).value()
  ), []);

  const filterOption = (input, option) => {
    const containNumber = option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    const containText = option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    return containNumber || containText;
  };

  return (
    <Form.Item name="code" noStyle>
      <Select
        showSearch
        optionFilterProp="children"
        size="large"
        filterOption={filterOption}
        style={{ width: 90 }}
      >
        {sorted.map((country) => {
          const countryName = _.get(country, 'ISO3166-1-Alpha-2', '').toUpperCase();
          const dial = `+${country.Dial}`;

          return (
            <Select.Option value={dial} key={countryName}>
              <span>
                {dial}
                {' '}
                {countryName}
              </span>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default CountryCodeSelector;
